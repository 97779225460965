<template>
   
  <!-- <div v-if="isShow"> -->
    <!--            <el-dialog
      title="文件预览"
      :visible.sync="isShow"
      width="100%"
      height="100%"
      :close-on-click-modal="false"
      v-dialogDrag
      custom-class="publicDialogs"
    >
           <iframe
        :src="text.title"
        frameborder="0"
        style="width: 100%; height: 100%"
      ></iframe>
           
    </el-dialog>
         
  </div> -->
  <div class="confirmationBox" v-if="isShow">
      <div class="out-line-box animate__animated animate__zoomIn">
        <div class="confirmation-content-box">
        <div class="publicDialogs">
        <div style="font-size: 18px;">文件预览</div>
        <div @click="isShow=false"><i style="font-size: 25px;" class="el-icon-close"></i></div>
    </div>
          <!-- <section class="left-top-corner">
            <div class="div1"></div>
            <div class="div2"></div>
          </section> -->
   <div style="flex:1;    background: #fff;box-sizing: border-box;
    padding: 5px;">
  <iframe :src="$baseUrl +text.title" frameborder="0" style="  display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;"></iframe>
   </div>
 
      
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "confirmationBox",
  data() {
    return {
      isShow: true,
      text: {
        title: "提示",
        msg: "描述信息！",
        cancelBtn: true,
        confirmBtn: true,
        btn: {
          confirmVal: "确定",
          cancelVal: "取消",
        },
      },
    };
  },
  mounted() {
    // this.isShow = false;
  },
  methods: {
    //取消
    cancel() {
      this.isShow = false;
    },
    //确认
    confirm() {
      this.isShow = false;
    },
  },
};
</script>
<style>
.publicDialogs {
  margin-top: 0 !important;
   color: #000;
   display: flex;
    justify-content: space-between;
    align-items: center;
 background:#069cff;
    padding: 10px !important;
}
.app-container .bottomBox{
    display: none;
}
 .publicDialogs   .el-dialog__header{
background:#069cff;
padding: 10px !important;
}
.publicDialogs .el-dialog__headerbtn .el-dialog__close{
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .publicDialogs   .el-dialog__body{
 width: 100%;
  height: 100%;
  padding: 0 !important;
}
</style>
<style scoped>
img {
        width: 100%;
}
body {
    margin: 0px;
  
}
.confirmationBox {
      width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
      box-sizing: border-box;
}

.mask-div {
  position: absolute;
  width: 100%;
  height: 100%;
  /*opacity: .5;*/
  /*background: #161a1c;*/
  background: #161a1cde;
  z-index: 9999999;
      box-sizing: border-box;
}

/*外边框*/
.out-line-box {
  width: 100%;
  height: 100%;
  z-index: 9999999;
  position: relative;
  top:0;
 box-sizing: border-box;
}
/*内容*/
.confirmation-content-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: 3px solid #63c6ff; */
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  /* box-shadow: 0 0 15px 6px #63c6ffa1 inset; */
}

.confirmation-title {
  /* color: #63c6ff; */
  font-size: 24px;
  position: relative;
  top: 30px;
  text-align: center;
  letter-spacing: 2px;
}

.confirmation-msg {
  color: #fff;
  font-size: 26px;
  position: relative;
  top: 60px;
  text-align: center;
  letter-spacing: 1px;
}

/*返回、确认按钮--*/
.btn-back-and-ok {
  position: absolute;
  bottom: 60px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.go-back-btn-out-line:hover {
  background: #c767605c;
  transition: 0.5s all;
  cursor: pointer;
}
.go-back-btn-out-line {
  box-shadow: 0 0 1px 1px #b7554d;
  height: 40px;
  width: 180px;
  margin: auto;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 9999999;
  position: relative;
  left: -15px;
  display: inline-block;
  line-height: 40px;
}
.go-back-btn {
  height: 100%;
  width: 100%;
  border: 2px solid #e06157;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0 0 8px 1px #b7554d inset;
  letter-spacing: 3px;
  color: #c76760;
}
.back-btn-left-top-corner {
  display: inline-block;
  height: auto;
  position: absolute;
  top: 0;
  left: 2px;
}
.back-btn-left-top-corner .div1 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #e06157;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(90deg);
  position: absolute;
  top: 10px;
}
.back-btn-left-top-corner .div2 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #e06157;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(180deg);
  position: absolute;
  top: 5px;
  left: 6px;
}
.back-btn-right-bottom-corner {
  display: inline-block;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 2px;
  transform: rotateZ(180deg);
}
.back-btn-right-bottom-corner .div1 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #e06157;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(90deg);
  position: absolute;
  top: 10px;
}
.back-btn-right-bottom-corner .div2 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #e06157;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(180deg);
  position: absolute;
  top: 5px;
  left: 6px;
}

.ok-btn-out-line:hover {
  background: #56a9db54;
  transition: 0.5s all;
  cursor: pointer;
}
.ok-btn-out-line {
  box-shadow: 0 0 1px 1px #63c6ffa1;
  height: 40px;
  width: 180px;
  margin: auto;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 9999999;
  position: relative;
  left: 15px;
  display: inline-block;
  line-height: 40px;
}
.ok-btn {
  height: 100%;
  width: 100%;
  border: 2px solid #63c6ff;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0 0 8px 1px #63c6ffa1 inset;
  letter-spacing: 3px;
  color: #56a9db;
}
.ok-btn-left-top-corner {
  display: inline-block;
  height: auto;
  position: absolute;
  top: 0;
  left: 2px;
}
.ok-btn-left-top-corner .div1 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #63c6ff;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(90deg);
  position: absolute;
  top: 10px;
}
.ok-btn-left-top-corner .div2 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #63c6ff;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(180deg);
  position: absolute;
  top: 5px;
  left: 6px;
}
.ok-btn-right-bottom-corner {
  display: inline-block;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 2px;
  transform: rotateZ(180deg);
}
.ok-btn-right-bottom-corner .div1 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #63c6ff;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(90deg);
  position: absolute;
  top: 10px;
}
.ok-btn-right-bottom-corner .div2 {
  height: 0;
  display: inline-block;
  width: 10px;
  border-bottom: 3px solid #63c6ff;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotateZ(180deg);
  position: absolute;
  top: 5px;
  left: 6px;
}

/*返回、确认按钮end*/

/*大盒子-左上角*/
.left-top-corner {
  display: inline-block;
  height: auto;
  position: relative;
  top: 15px;
  left: 7px;
}
.left-top-corner .div1 {
  height: 0;
  display: inline-block;
  width: 20px;
  border-bottom: 5px solid #63c6ff;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotateZ(90deg);
  position: relative;
  top: 5px;
}
.left-top-corner .div2 {
  height: 0;
  display: inline-block;
  width: 20px;
  border-bottom: 5px solid #63c6ff;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotateZ(180deg);
  position: relative;
  top: -8px;
  left: -12px;
}
/*左上角end*/

/*大盒子-右下角*/
.right-bottom-corner {
  display: inline-block;
  height: auto;
  position: absolute;
  right: 7px;
  bottom: 15px;
  transform: rotateZ(180deg);
}
.right-bottom-corner .div1 {
  height: 0;
  display: inline-block;
  width: 20px;
  border-bottom: 5px solid #63c6ff;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotateZ(90deg);
  position: relative;
  top: 5px;
}
.right-bottom-corner .div2 {
  height: 0;
  display: inline-block;
  width: 20px;
  border-bottom: 5px solid #63c6ff;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotateZ(180deg);
  position: relative;
  top: -8px;
  left: -12px;
}
/*右下角end*/
</style>