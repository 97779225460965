// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hide: 1,
  },
  getters: {
  },
  mutations: {
    OPENSHOW(state,step) {
      state.hide = step;
    },
  },
  actions: {
    AsyicOPENSHOW(context, step) {
      context.commit('OPENSHOW',step)
    },
  },
  modules: {
  }
})
