<template>
  <div>
        <div class="bottomBox">
      <p> 河南省豫资大数据研究院有限公司版权所有，未经书面授权禁止使用  <br /> Copyright © 2022-2023 by www.train.hnzyxsbh.com all rights reserved </p>
     
      <!-- <div class="cinonimg">
        <img src="@/assets/button/copy_icon1.png" alt="" srcset="">
           <img src="@/assets/button/copy_icon2.png" alt="" srcset="">
              <img src="@/assets/button/copy_icon3.png" alt="" srcset="">
                 <img src="@/assets/button/copy_icon4.png" alt="" srcset="">
                    <img src="@/assets/button/copy_py2.png" alt="" srcset="">
                       <img src="@/assets/button/wzahg.png" alt="" srcset="">
      </div> -->
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.bottomBox {
    // padding-top: 3px;
    justify-content: center;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    // width: 100%;
    align-content: center;
    height: 61px;
    padding: 9px 0;
    text-align: center;
    line-height: 22px;
    font-size: 14px;
    color: #999;
    background: #fff;
}
.cinonimg{
  width: 100%;
  padding: 1% 15%;
  display: flex;
  justify-content: space-around;
}
</style>