import request from "@/utils/request"



//个人中心
export const grzx = (user_id) => {

  return request({
    method: "POST",
    data: {
      // user_id
    },
    url: "apply/index/usercenter",
  });
};
// 获取快递信息
export function kuaidi(params) {
  return request({
      url: '/apply/kuaidi/order_list',
      method: 'POST',
      data: params
  })
}
//退出登录
export const out = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/api/index/logout",
  });
};
// 企业资质
export const zizhi = () => {

  return request({
    method: "POST",
    url: "apply/company/zizhi",
  });
};
// 企业业绩
export const yeji = (params) => {

  return request({
    method: "POST",
    data: params,
    url: "apply/company/yeji",
  });
};
export const gongsirenzheng = (e) => {

  return request({
    method: "POST",
    data: {
      // user_id: sessionStorage.getItem("user_id")
    },
    url: "apply/company/getinfo",
  });
};
//公章使用记录
export const seal = (e) => {

  return request({
    method: "POST",
    data: {
      // user_id: sessionStorage.getItem('user_id')
    },
    url: "apply/index/gongsizhang",
  });
};
//法人章使用记录
export const corporate = (e) => {

  return request({
    method: "POST",
    data: {
      // user_id: sessionStorage.getItem('user_id')
    },
    url: "apply/index/farenzhang",
  });
};
//修改密码
export const modify = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "apply/staff/password",
  });
};
//企业认证
export const companyadd = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/company/company_add",
  });
};
//章认证
export const companyseal = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/company/companyseal",
  });
};
// 营业执照识别
// /
export const zhizhao = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/company/zhizhao",
  });
};
// 根据社会统一代码查询公司信息
export const companyinfo = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/company/companyinfo",
  });
};
// 根据身份证
export const idcard = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/company/idcard",
  });
};
// 查询企业认证
export const getinfo = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/company/getinfo",
  });
};
// 查询企业认证
export const addresslist = (pass) => {

  return request({
    method: "POST",
    data: pass,
    url: "/apply/address/addresslist",
  });
};

