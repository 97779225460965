<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default{

    watch: {
$route(to, from) {
if (to.path == '/Guarantee/shenhe' || to.path=="/BidSecurity/SelectALot/" || to.path=="/ggxq" || to.path=="/bhxq" || to.path=="/BidSecurity/financialInstitution"
|| to.path=="/BidSecurity/Submission" || to.path=="/BidSecurity/bidFrom" || to.path=="/BidSecurity/bidFroms" || to.path=="/BidSecurity/ElectronicProtocol" || to.path=="/BidSecurity/Protocol"
|| to.path=="/BidSecurity/BidPayment" || to.path=="/BidSecurity/puBlic" || to.path=="/personal"  || to.path=="/Exxpress"|| to.path=="/zbxq" || to.path=="/jgbg" || to.path=="/ggbg"  || to.path=="/personalUser" 
|| to.path=="/BidSecurity/myinvoice"  || to.path=="/finance/detail" || to.path=="/kuaidi" || to.path=="/fdbxq" || to.path=="/daihouguanli/detail"  || to.path=="/msg/index" ||  to.path=="/zhaoxq"
) {
                this.$store.dispatch("AsyicOPENSHOW", '');
            } else {
                this.$store.dispatch("AsyicOPENSHOW", 1);
            }
        }
    },
	}
</script>
<style>
.Content .apply_rz {
    display: none;
    margin: 20px 20px;
    float: right;
    font-size: 20px;
    border: 1px #1890FF solid;
    padding: 3px 10px;
    background: #007bff;
    color: #fff !important;
}
/* .el-tabs__content {
 padding: 6px !important;
} */
.block{
            position: static !important;
            display: flex !important;
padding-right: 20px !important;
justify-content: flex-end !important;
}
  .xinde .el-table__body-wrapper {
 /* height: 70vh; */
  height: 66.5vh;
    overflow: auto;
    position: relative;
}
.app-container {
    height: calc(100vh - 132px) !important;
    /* padding-bottom: 0px;
    position: relative; */
}
  .xindes .el-table__body-wrapper {
  height: 58vh !important;
  /* height: 62vh!important; */
    overflow: auto;
    position: relative;
    
}
.table {
  width: 100%;
  margin-top: 0vh !important;
}
  </style>
</style>
<style lang="scss">
@import url('./utils/css/common.scss');
*{margin:0;padding:0}li{list-style:none}img{vertical-align:top;border:none}
/*清除默认样式*/
html, body, div, ul, li, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ol, form, input, textarea, th, td, select {
    margin: 0;
    padding: 0;
}
*{box-sizing: border-box;}
html, body,#app {
//    height: 100%;
}

body {
    font-family: "Microsoft YaHei";
    font-size:14px;
    color:#333;
}
h1, h2, h3, h4, h5, h6{font-weight:normal;}
ul,ol {
    list-style: none;
}

img {
    border: none;
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: #232323;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
}

input, textarea {
    outline: none;
    border: none;
}

textarea {
    resize: none;
    overflow: auto;
}

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
