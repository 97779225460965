import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/homepage/homepage",
    children: [
      //首页
      {
        path: "homepage/homepage",
        name: "homepage",
        component: () => import("../views/homepage/HomepageView.vue"),
      },
      //  工程信息 //采购公告
      {
        path: "information/Purchase",
        name: "Purchase",
      },
      //公开招标
      {
        path: "information/Purchase/gkzb",
        name: "gkzb",
        component: () => import("../views/information/Purchase/GkzbView.vue"),
      },
      //竞争性谈判公告
      {
        path: "information/Purchase/negotiation",
        name: "negotiation",
        component: () =>
          import("../views/information/Purchase/NegotiationView.vue"),
      },
      //竞争性磋商公告
      {
        path: "information/Purchase/consultations",
        name: "consultations",
        component: () =>
          import("../views/information/Purchase/ConsultationsView.vue"),
      },
      //邀请招标公告
      {
        path: "information/Purchase/invitation",
        name: "invitation",
        component: () =>
          import("../views/information/Purchase/InvitationView.vue"),
      },
      //询价公告
      {
        path: "information/Purchase/inquiry",
        name: "inquiry",
        component: () =>
          import("../views/information/Purchase/InquiryView.vue"),
      },
      //单一来源公告
      {
        path: "information/Purchase/source",
        name: "source",
        component: () => import("../views/information/Purchase/SourceView.vue"),
      },
      //资格预审
      {
        path: "daihouguanli/list",
        name: "daihouguanli/list",
        component: () => import("../views/daihouguanli/list.vue"),
        meta: { title: "工程列表" },
      },
      {
        path: "/daihouguanli/jindu",
        name: "daihouguanli/jindu",
        component: () => import("../views/daihouguanli/jindu.vue"),
        meta: { title: "工程进度" },
      },
      {
        path: "/daihouguanli/jeibao",
        name: "daihouguanli/jeibao",
        component: () => import("../views/daihouguanli/jiebaoList.vue"),
        meta: { title: "工程进度" },
      },
      {
        path: "/daihouguanli/xubao",
        name: "daihouguanli/xubao",
        component: () => import("../views/daihouguanli/xubaoList.vue"),
        meta: { title: "工程进度" },
      },
      {
        path: "/daihouguanli/fangqi",
        name: "daihouguanli/fangqi",
        component: () => import("../views/daihouguanli/fangqiList.vue"),
        meta: { title: "工程进度" },
      },

      {
        path: "information/Purchase/Prequalification",
        name: "Prequalification",
        component: () =>
          import("../views/information/Purchase/PrequalificationView.vue"),
      },
      //  工程信息 //结果公告
      {
        path: "information/result",
        name: "result",
      },
      {
        path: "information/result/bid",
        name: "bid",
        component: () => import("../views/information/result/BidView.vue"),
      },
      {
        path: "information/result/deal",
        name: "deal",
        component: () => import("../views/information/result/DealView.vue"),
      },
      //  工程信息 //更正公告
      {
        path: "information/gesult",
        name: "result",
      },
      {
        path: "information/gesult/bid",
        name: "bid",
        component: () => import("../views/information/gesult/BidView.vue"),
      },
      {
        path: "information/gesult/deal",
        name: "deal",
        component: () => import("../views/information/gesult/DealView.vue"),
      },
      //员工管理
      {
        path: "staff/account",
        name: "account",
        component: () => import("../views/staff/AccountView.vue"),
      },

      {
        path: "staff/jurisdiction",
        name: "jurisdiction",
        component: () => import("../views/staff/JurisdictionfView.vue"),
      },

      //保函管理

      {
        path: "Guarantee/apply",
        name: "apply",
        component: () => import("../views/Guarantee/ApplyView.vue"),
      },
      {
        path: "Guarantee/list",
        name: "list",
        component: () => import("../views/Guarantee/ListView.vue"),
      },
      {
        path: "/bhxq",
        name: "bhxq",
        component: () => import("../views/Guarantee/baohan/bhxq.vue"),
        meta: { title: "保函详情" },
      },
      {
        path: "/zhaoxq",
        name: "zhaoxq",
        component: () => import("../views/Guarantee/baohan/zhaoxq.vue"),
        meta: { title: "保函详情" },
      },

      {
        path: "/fdbxq",
        name: "fdbxq",
        component: () => import("../views/Guarantee/baohan/fdbxq.vue"),
        meta: { title: "反担保保函详情" },
      },
      {
        path: "/ggxq",
        name: "ggxq",
        component: () => import("../views/Guarantee/baohan/ggxq.vue"),
        meta: { title: "公告详情" },
      },
      {
        path: "/zbxq",
        name: "zbxq",
        component: () => import("../views/Guarantee/baohan/zbxq.vue"),
        meta: { title: "公告详情" },
      },
      {
        path: "/jgbg",
        name: "jgbg",
        component: () => import("../views/Guarantee/baohan/jgbg.vue"),
        meta: { title: "公告详情" },
      },
      {
        path: "/ggbg",
        name: "ggbg",
        component: () => import("../views/Guarantee/baohan/ggbg.vue"),
        meta: { title: "公告详情" },
      },
      {
        path: "Guarantee/receipt",
        name: "receipt",
        component: () => import("../views/Guarantee/receipts.vue"),
      },
      {
        path: "Guarantee/guarantee",
        name: "guarantee",
        component: () => import("../views/Guarantee/GuaranteeView.vue"),
      },
      {
        path: "/daihouguanli/detail",
        name: "daihouguanli/detail",
        component: () => import("../views/daihouguanli/detail.vue"),
      },
      {
        path: "Guarantee/guaranren",
        name: "guaranren",
        component: () => import("../views/Guarantee/GuaranrenView.vue"),
      },
      {
        path: "Guarantee/fguarantee",
        name: "fguarantee",
        component: () => import("../views/Guarantee/fguarantee.vue"),
      },
      // 财务管理
      {
        path: "finance/payment",
        name: "payment",
        component: () => import("../views/finance/PaymentView.vue"),
      },
      {
        path: "/msg/index",
        name: "msg/index",
        component: () => import("../views/biao/index.vue"),
        meta: { title: "系统通知" },
      },
      {
        path: "finance/payments",
        name: "payment",
        component: () => import("../views/finance/baohan/shouru.vue"),
      },
      {
        path: "finance/refund",
        name: "refund",
        component: () => import("../views/finance/RefundView.vue"),
      },
      {
        path: "finance/invoice",
        name: "invoice",
        component: () => import("../views/finance/InvoiceView.vue"),
      },
      {
        path: "/BidSecurity/SelectALot",
        name: "SelectALot",
        component: () => import("../views/BidSecurity/SelectALot.vue"),
      },
      //2
      {
        path: "/BidSecurity/financialInstitution",
        name: "financialInstitution",
        component: () =>
          import("../views/BidSecurity/financialInstitution.vue"),
      },
      {
        path: "/BidSecurity/Submission",
        name: "SubmiSsion",
        component: () => import("../views/BidSecurity/Submission.vue"),
      },
      //4
      {
        path: "/BidSecurity/bidFrom",
        name: "bidFrom",
        component: () => import("../views/BidSecurity/bidFrom.vue"),
      },
      {
        path: "/BidSecurity/bidFroms",
        name: "bidFrom",
        component: () => import("../views/BidSecurity/bidFroms.vue"),
      },
      //5
      {
        path: "/BidSecurity/ElectronicProtocol",
        name: "ElectronicProtocol",
        component: () => import("../views/BidSecurity/ElectronicProtocol.vue"),
      },
      //6
      {
        path: "/BidSecurity/Protocol",
        name: "Protocol",
        component: () => import("../views/BidSecurity/Protocol.vue"),
      },
      // 7
      {
        path: "/BidSecurity/BidPayment",
        name: "BidPayment",
        component: () => import("../views/BidSecurity/BidPayment"),
      },
      //8
      {
        path: "/BidSecurity/puBlic",
        name: "puBlic",
        component: () => import("../views/BidSecurity/puBlic.vue"),
      },
      //9
      {
        path: "/BidSecurity/myinvoice",
        name: "myinvoice",
        component: () => import("../views/BidSecurity/myinvoice.vue"),
      },
      // 经办人
      {
        path: "qualifications/qlist",
        name: "qlist",
        component: () => import("../views/qualifications/QlistView.vue"),
      },
      {
        path: "qualifications/examine",
        name: "examine",
        component: () => import("../views/qualifications/ExamineView.vue"),
      },
      {
        path: "/personal",
        name: "PersonalView",
        component: () => import("../views/PersonalView.vue"),
      },
      {
        path: "/personalUser",
        name: "PersonalView",
        component: () => import("../views/personalUserView.vue"),
      },
      {
        path: "/Exxpress",
        name: "Exxpress",
        component: () => import("@/views/biao/Expresse.vue"),
      },
      {
        path: "/finance/detail",
        name: "detail",
        component: () => import("@/shenji/detail.vue"),
      },
      {
        path: "/kuaidi",
        name: "kuaidi",
        component: () => import("@/views/biao/kuaidi.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "loginView",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "registerView",
    component: () => import("../views/RegisterView.vue"),
  },

  //详情页
  {
    path: "/details",
    name: "DetailsView",
    component: () => import("../views/DetailsView.vue"),
  },

  //实名认证
  {
    path: "/RealName/PersonalRealname",
    name: "PersonalRealname",
    component: () => import("../views/RealName/PersonalRealname.vue"),
  },
  //实名认证
  {
    path: "/RealName/PersonalRealnames",
    name: "PersonalRealname",
    component: () => import("../views/RealName/PersonalRealnames.vue"),
  },
  {
    path: "/RealName/RealnameInformation",
    name: "RealnameInformation",
    component: () => import("../views/RealName/RealnameInformation.vue"),
  },
];
const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    to.path !== "/login" &&
    to.path !== "/register" &&
    !sessionStorage.getItem("token")
  ) {
    next("/login");
  } else {
    next();
  }

  // if (to.name == 'login') {
  // 	console.log(to.name);
  // }
  // next()
});

export default router;
